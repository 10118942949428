<template>
    <v-content>
        <v-breadcrumbs :items="items" large>
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to"
                                    :disabled="item.disabled"
                                    :exact="item.exact">
                    {{ $t(item.text) }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-container class="">
            <v-snackbar v-model="message"
                        color="success"
                        :timeout="callbackResponse.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark text @click="message = false">Close</v-btn>
            </v-snackbar>
            <div slot="table-actions">
                <!--<v-btn color="primary">{{ $t("report") }}</v-btn>-->

                <v-row class="float-right">
                    <!--<v-btn @click="importExportModal" class="warning float-right mx-4">

                          Import
                      </v-btn>-->
                </v-row>
            </div>
            <v-dialog width="50%"
                      persistent
                      transition="dialog-bottom-transition"
                      v-model="snackbar.importExportComponent">
                <ImportExportModal :ieData="ieData"
                                   @importExportModalResponse="importExportModalResponse"
                                   v-if="snackbar.importExportComponent"></ImportExportModal>
            </v-dialog>

            <v-dialog v-model="editForm" fullscreen hide-overlay max-width="500px">
                <CustomerInformation v-if="editForm"
                                     :hotelCustomerData="hotelCustomerData"
                                     @formResponse="onResponseUpdate"
                                     @formResponseClose="onResponseClose" />
            </v-dialog>

            <v-dialog v-model="deleteData.dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="deleteData"
                                @formResponse="onDeleteResponse"></deleteListData>
            </v-dialog>

            <v-expansion-panels hover>
                <v-expansion-panel class="fix-for-nepali-label">
                    <v-expansion-panel-header>
                        {{
            $t("filter")
                        }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start">
                                    <v-col class="d-flex" cols="12" sm="3" >
                                                  <v-card-text>
                                                              <v-select :items="house" item-value="id"
                                                                        clearable
                                                                        dense
                                                                        outlined
                                                                        item-text="value" v-model="serverParams.house">
                                                                  <template v-slot:label>
                                                                      {{ $t("select_house") }}
                                                                  </template>
                                                              </v-select>
                                                  </v-card-text>
                                              </v-col>
                                    <!--<v-col class="d-flex" cols="12" sm="4">
                                                  <v-card-text>
                                                      <v-row>
                                                          <v-col>
                                                              <v-text-field v-model="serverParams.houseNumber" label="House Number" clearable></v-text-field>
                                                          </v-col>

                                                      </v-row>
                                                  </v-card-text>
                                              </v-col>

                                              <v-col class="d-flex" cols="12" sm="4">
                                                  <v-card-text>
                                                      <v-row>
                                                          <v-col>
                                                              <v-text-field label="House Owner Name"
                                                                        clearable v-model="serverParams.houseOwner"></v-text-field>
                                                          </v-col>

                                                      </v-row>
                                                  </v-card-text>
                                              </v-col>-->
                                    <!--<v-col class="d-flex" cols="12" sm="4">
                                                  <v-card-text>
                                                      <v-row>
                                                          <v-col>
                                                              <v-text-field v-model="serverParams.houseOwner" label="House Owner Name" clearable"></v-text-field>
                                                          </v-col>

                                                      </v-row>
                                                  </v-card-text>
                                              </v-col>-->

                                    <v-col class="d-flex text-center mt-4" cols="12" sm="3">
                                        <v-menu v-model="snackbar.menu1"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                :close-on-content-click="false"
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.FromDate"
                                                              clearable
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              dense
                                                                        outlined
                                                              v-on="on">
                                                    <template v-slot:label>
                                                        {{ $t("from_date") }}
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex mt-4"
                                           cols="12"
                                           sm="3"
                                           v-if="!reportIsMonthWise">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              clearable
                                                              prepend-inner-icon="event"
                                                              readonly
                                                              dense
                                                                        outlined
                                                              v-on="on">
                                                    <template v-slot:label>
                                                        {{ $t("to_date") }}
                                                    </template>
                                                </v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2"
                                               dark
                                               small
                                               color="primary"
                                               @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{ $t("search") }}
                                        </v-btn>

                                        <v-btn @click.stop="onDownload"
                                               small
                                               class="success float-right mx-4">
                                            <v-icon>get_app</v-icon>
                                            {{ $t("download") }}
                                        </v-btn>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table class="narrow-table"
                            mode="remote"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-column-filter="onColumnFilter"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRecords"
                            :isLoading.sync="isLoading"
                            :pagination-options="{ enabled: true, mode: 'records' }"
                            :rows="hotelRoomReport"
                            :columns="columns">
                <template slot="table-column" slot-scope="props">
                    <div class="cell-wrapper">
                        <span>
                            {{ $t(props.column.label) }}
                        </span>
                    </div>
                </template>
                <template slot="table-row" slot-scope="props">
                    <tbody>
                        <tr :class="
                props.row.isCustomerCheckOut
                  ? 'green accent-2'
                  : 'purple lighten-5'
              "
                            v-for="rdata in rows"
                            :key="rdata.id">
                            <td>{{ rdata.amount }}</td>
                            <td>{{ rdata.hotelCustomerID }}</td>
                        </tr>
                    </tbody>
                </template>

                <template :class="
            props.row.isCustomerCheckOut ? 'green accent-2' : 'purple lighten-5'
          "
                          slot="table-row"
                          slot-scope="props">
                    <span v-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
                        <v-icon small @click="deleteItem(props.row)">delete</v-icon>
                    </span>

                    <span v-else-if="props.column.field == 'roomReserved'">
                        <span v-if="props.row.roomReserved == 0">
                            Yes
                        </span>
                        <span v-else>
                            No
                        </span>
                    </span>

                    <span v-else-if="props.column.field == 'checkInDate'">
                        {{ props.row.checkInDate | moment("DD-MMM-YYYY") }}
                    </span>
                    <span v-else-if="props.column.field == 'checkOutDate'">
                        {{
            props.row.checkOutDate | moment("DD-MMM-YYYY")
                        }}
                    </span>
                    <span v-else-if="props.column.field == 'isCustomerCheckOut'">
                        <v-icon v-if="props.row.isCustomerCheckOut" small class="mr-2">done</v-icon>
                        <v-icon v-else>close</v-icon>
                    </span>
                </template>
            </vue-good-table>
        </v-container>

        <v-dialog v-model="createForm"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">
            <stepper-form-component v-if="createForm"
                                    :dialogue="createForm"
                                    :pendingData="pendingData"
                                    @formResponse="onResponse"
                                    @formResponseClose="onResponseClose"></stepper-form-component>
        </v-dialog>

        <!--        <v-dialog v-model="editForm" fullscreen hide-overlay transition="dialog-bottom-transition">-->
        <!--            <editForm-->
        <!--                    v-if="editForm"-->
        <!--                    :hotelCustomerID="hotelCustomerID"-->
        <!--                    @formResponse="onResponse"-->
        <!--                    @formResponseClose="onResponseClose"-->
        <!--            />-->
        <!--        </v-dialog>-->

        <v-dialog v-model="showForm"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">
            <showForm v-if="showForm"
                      :hotelCustomerID="hotelCustomerID"
                      @formResponseClose="onResponseClose" />
        </v-dialog>
        <v-btn dark color="red darken-1" @click="dialogueClose" to="HomeStayDashboard">
            {{$t("back")}}
        </v-btn>
    </v-content>
    
</template>

<script>
import axios from "axios";
import createForm from "./create";
// import editForm from "./edit";
import showForm from "./show";
import CustomerInformation from "./customerInformation";
import deleteListData from "@/components/deleteModal";
import ImportExportModal from "@/components/ImportExport/ImportExportModal";

export default {
  name: "createAdminForm",
  props: ["success"],
  components: {
    "stepper-form-component": createForm,
    CustomerInformation,
    // editForm,
    showForm,
    deleteListData,
    ImportExportModal,
  },
  data() {
    return {
      /*user:2,*/
      ieData: {
        import: "Hotel/Import",
      },
      callbackResponse: {
        timeout: 6000,
      },
      items: [
        {
          text: "dashboard",
          disabled: false,
          to: "HomeStayDashboard",
          exact: true,
        },
        {
          text: "report",
          disabled: true,
        },
      ],
      message: false,
      totalDesserts: 0,
      apiData: [],
      house: [],
      pendingData: [],
      loading: true,
      createForm: false,
      editForm: false,
      showForm: false,
      dialogCustomerInformation: false,
      dialogEditCustomerInformation: false,

      snackbar: {
        importExportComponent: false,
        snackbar: false,
        text: "Internal Server Error,Please Contact Admin",
        timeout: 200000,
        menu2: false,
        save_button: false,
        next_button: true,
        success: false,
        error: false,
        number_of_second_form: 1,
        price: 0,
      },
      serverParams: {
        search: "",
        columnFilters: {},
        sort: [
          {
            field: "countIndex",
            type: "desc",
          },
        ],
        page: 1,
        perPage: 10,
        houseNumber: "",
        houseOwner: "",
      },
      columns: [
        //{
        //    label: "S No.",
        //    field: "countIndex",
        //    width: '70px'
        //},

        { label: "s_no", field: "index" },
        { label: "house_name", field: "houseName" },
        { label: "house_no", field: "houseNumber" },
        { label: "house_owner_name", field: "houseOwner" },
        { label: "customers", field: "customerCount" },

        //{ label: "TotalPerson", width: '280px', field: "fullName" },
        //{ label: "TotalRomms", width: '170px', field: "passportNumber" },
        //{ label: "Amount", width: '150px', field: "checkInDate" },
        //{ label: "RoomCategoryId", width: '150px', field: "checkOutDate" }
        //{ label: "Checkout Status", width: '90px', field: "roomReserved" },
        //{ label: "Actions", width: '70px', field: "actions" }
      ],
      rows: [],
      province: [],
      division: [],
      houseRows: [],
      filterEditedData: {},
      hotelRoomReport: [],
      dialogData: {},
      formdata: [],
      totalRecords: 0,
      isLoading: false,
      deleteData: {
        dialogDelete: false,
        url: "",
      },
    };
  },
  mounted() {
    this.loadItems();
    axios
      .get("Hotel/HouseList")
      .then((response) => (this.house = response.data));
    ////axios.post("Hotel/GetHomestayHouseListAsync")
    ////    .then(response => {
    ////        console.log(response)
    ////        this.houseRows = response.data.data;

    ////        this.totalRecords = response.data.totalCount;
    ////    })

    ////    .catch(err => {
    ////        console.log(err)
    ////    });
  },
  destroyed() {
    console.log(this); // There's practically nothing here!
  },
  methods: {
    // async onImport() {
    //     var form = new FormData();

    //     form.append(
    //         "formFile",
    //         this.formdata.import
    //     );
    //     let ImportResponse = await axios.post("Hotel/Import", form);
    //     if (ImportResponse.data.success) {
    //         this.loadItems();
    //         this.formdata.import = null;
    //     } else {
    //         return false;
    //     }
    // },
    importExportModalResponse(data) {
      this.snackbar.importExportComponent = false;
      if (data.modal) {
        this.message = true;
        this.callbackResponse.message = data.message;
        this.loadItems();
      }
    },
    importExportModal() {
      this.snackbar.importExportComponent = true;
    },
    async onDownload() {
      let param = {
        PageSize: this.serverParams.perPage,
        PageNo: this.serverParams.page,
        OrderType: this.serverParams.sort[0].type,
        OrderBy: this.serverParams.sort[0].field,
      };

      await axios({
          url: "Hotel/DownloadReport",
        method: "POST",
        responseType: "blob",
        data: param,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "HomestayFile.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    onResponse(val) {
      if (val) {
        this.dialogCustomerInformation = false;
        this.message = true;
        this.callbackResponse.message =
          "Customer Information Updated Successfully.";
        this.editForm = false;
        this.createForm = false;
        this.loadItems();
      }
    },

    onResponseUpdate(val) {
      if (val) {
        this.loadItems();
      }
    },

    onDeleteResponse(data) {
      console.log(data);
      if (data.message) {
        this.dialogCustomerInformation = false;
        this.message = true;
        this.callbackResponse.message =
          "Customer Information Deleted Successfully.";
        this.deleteData.dialogDelete = false;
        this.loadItems();
      } else {
        this.callbackResponse.message = "";
        this.deleteData.dialogDelete = false;
      }
    },
    onResponseClose() {
      console.log("here");
      this.dialogCustomerInformation = false;
      this.editForm = false;
      this.createForm = false;
      this.showForm = false;
    },
    deleteItem(props) {
      console.log(props);
      this.deleteData.dialogDelete = true;
      this.deleteData.url =
        "Hotel/DeleteHotelCustomerAsync/" + props.hotelCustomerID;
      this.loadItems();
    },
    editItem(item) {
      console.log("listClicked", item);
      this.editForm = true;
      this.hotelCustomerData = item;
    },
    showItem(item) {
      this.showForm = true;
      this.hotelCustomerID = item.hotelCustomerID;
    },
    closeForm() {
      this.editForm = false;
      this.showForm = false;
    },
    customerInformationMethod(props) {
      this.hotelCustomerID = props.hotelCustomerID;
      this.dialogCustomerInformation = true;
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async loadItems() {
      this.loading = true;

      let param = {
        //PageSize: this.serverParams.perPage,
        //PageNo: this.serverParams.page,
        //OrderType: this.serverParams.sort[0].type,
        //OrderBy: this.serverParams.sort[0].field,
        //PassportNumber: this.serverParams.passportNo,
        //FullName: this.serverParams.FullName,
        //TotalPerson: this.serverParams.TotalPerson,
        FromDate: this.serverParams.FromDate,
        HouseId: this.serverParams.house != null && this.serverParams.house != undefined ? parseInt(this.serverParams.house) : 0,
        HouseNumber: this.serverParams.houseNumber,
        HouseOwner: this.serverParams.houseOwner,
        ToDate: this.serverParams.ToDate,
      };
      //{ label: "House Name", width: '280px', field: "houseName" },

      axios
        .post("Hotel/RoomReport", param)
        .then((response) => {
          if (response.data) {
            let indexData = response.data.map((response, index) => ({
              s_no: index + 1,
              ...response,
            }));
            console.log("this >>> ", indexData);
            let head = Object.keys(indexData[0]);
            this.columns = head.map((res) => ({
              label: res,
              field: res,
              sortable: false,
            }));
            this.hotelRoomReport = indexData;
            this.totalRecords = response.data.totalCount;
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("load", this.apiData);
    },
  },
};
</script>

<style lang="scss" scoped>
.pasForm {
  margin-top: 4em;
}

.square-checkout {
  height: 1.5em;
  width: 1.5em;
  background-color: #69f0ae;
}

.square-not-checkout {
  height: 1.5em;
  width: 1.5em;
  background-color: #f3e5f5;
}
</style>

<style lang="scss">
/* css rules for manual table styles "this must not be scoped" */
$table-font-size: 15px;
.vgt-wrap.narrow-table {
  .vgt-table thead th,
  .vgt-table.bordered td {
    vertical-align: middle;
    font-size: $table-font-size;
    padding: 8px;
    text-align: center;
  }
}
</style>
